<template>
  <section class="tip-item">
    <div class="tip-item__section tip-item__section--content">
      <div>
        <header class="tip-item__header">
          <h3 class="tip-item__title">
            <InsiderTipElementPageLink :element="element" :entityRoute="entityRoute">{{
              element.title
            }}</InsiderTipElementPageLink>
          </h3>
          <div class="small-grey">{{ element.type }}</div>
          <div class="small-grey">Address: {{ element.nearest_metro }}</div>
        </header>

        <div class="tip-item__desc" :class="{ 'tip-item__desc--full': isDescriptionFullHeight }">
          <div
            ref="descElem"
            class="desc"
            :class="{ open: isDescriptionOpened }"
            :style="`max-height:${maxHeightLimitStyle}`"
          >
            <div class="desc__inner" v-html="element.details" />
          </div>
          <button class="read-more" @click="toggleDescriptionHeight">
            <span v-if="!isDescriptionOpened">read more</span>
            <span v-else>read less</span>
          </button>
        </div>
      </div>

      <div>
        <InsiderTipElementPageLink :element="element" :entity-route="entityRoute">
          <img v-if="mediaUrl" class="tip-item__img" :src="mediaUrl" :alt="element.title" />
          <div v-else class="tip-item__without-img" />
        </InsiderTipElementPageLink>
      </div>
    </div>

    <div class="tip-item__section tip-item__section--actions">
      <slot name="actions" />
    </div>
  </section>
</template>

<script>
import { getEntityRoute } from '@/helpers/entityType';
import handleResizeWithDebounce from '@/helpers/windowResizeHandlerWithDebounce';

import InsiderTipElementPageLink from '@/components/partials/InsiderTipElementPageLink';

const DESC_BLOCK_HEIGHT_LIMIT_DESKTOP = 160;
const DESC_BLOCK_HEIGHT_LIMIT_MOBILE = 120;

export default {
  name: 'InsiderTipElement',
  components: { InsiderTipElementPageLink },
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isDescriptionFullHeight: false,
      isDescriptionOpened: false,
      maxHeightLimit: 0,
    };
  },
  computed: {
    entityRoute() {
      return this.element.entityable ? getEntityRoute(this.element.entityable) : null;
    },
    maxHeightLimitStyle() {
      return this.maxHeightLimit ? `${this.maxHeightLimit}px` : 'none';
    },
    mediaUrl() {
      if (!this.element.media || !this.element.media.length) {
        return '';
      }
      return this.element.media[0].url;
    },
  },

  mounted() {
    this.setDescriptionFullHeight();

    this._debounceResize = handleResizeWithDebounce();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    this._debounceResize = null;
  },

  methods: {
    handleResize() {
      this._debounceResize(this.setDescriptionFullHeight);
    },
    setDescriptionFullHeight() {
      this.maxHeightLimit = window.outerWidth > 768 ? DESC_BLOCK_HEIGHT_LIMIT_DESKTOP : DESC_BLOCK_HEIGHT_LIMIT_MOBILE;
      this.isDescriptionFullHeight = this.$refs.descElem.scrollHeight <= this.maxHeightLimit;
      this.isDescriptionOpened = this.isDescriptionFullHeight;
    },
    toggleDescriptionHeight() {
      this.isDescriptionOpened = !this.isDescriptionOpened;
    },
  },
};
</script>
