<template>
  <a v-if="element.external_site_url" :href="element.external_site_url" rel="noopener nofollow" target="_blank">
    <slot />
  </a>
  <RouterLink v-else-if="entityRoute" :to="entityRoute">
    <slot />
  </RouterLink>
  <div v-else>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'InsiderTipElementPageLink',
  props: {
    element: {
      type: Object,
      required: true,
    },
    entityRoute: {
      type: Object,
      default: null,
    },
  },
};
</script>
